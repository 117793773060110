import {
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ZodFormUtilities } from '@jfw-library/ecommerce/zod-forms';
import {
  DateFormComponent,
  DateFormModule,
} from '@jfw-library/shared/common-components/form-fields';
import { getMaxDateForEvent, getMinEventDateToday } from 'business-logic';
import { Event, EventSchema } from 'common-types';
import { ObservedValueOf } from 'rxjs';

interface DateForm {
  eventDate?: ObservedValueOf<DateFormComponent['formReady']>;
}

@Component({
  selector: 'app-event-date',
  templateUrl: './event-date.component.html',
  styleUrls: ['./event-date.component.scss'],
  standalone: true,
  imports: [DateFormModule, MatButtonModule, ReactiveFormsModule],
})
export class EventDateComponent implements OnInit {
  private _event = signal<Event | undefined>(undefined);
  @Input() set event(ev: Event | undefined) {
    this._event.set(ev);
    this
  }

  existingEventDate = computed(() => this._event()?.eventDate ?? 0);


  @Output() setEvent = new EventEmitter<Event>();
  @Output() setStepNumber = new EventEmitter<number>();

  minDate = getMinEventDateToday();
  maxDate = getMaxDateForEvent();

  dateForm = new FormGroup({
    eventDate: new FormControl<number>(this.existingEventDate(), {
      nonNullable: true,
      validators: [
        ZodFormUtilities.zodFormControlValidator(EventSchema.shape.eventDate)
      ]
    })
  });

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    console.log('this.dateForm:', this.dateForm);
    this.dateForm.controls.eventDate.patchValue(this.existingEventDate());
  }

  // ngAfterViewChecked(): void {
  //   this.cdr.detectChanges();
  // }

  setDate(eventDate: number | undefined) {
    console.log('date in event-date.component setDate:', eventDate);
    if (eventDate === undefined) {
      this.dateForm.controls.eventDate.setValue(0);
    } else {
      this.dateForm.controls.eventDate.setValue(eventDate);
      this.dateForm.controls.eventDate.markAsDirty();
    }
  }

  // addChildForm<key extends keyof DateForm>(
  //   name: key,
  //   group: Exclude<DateForm[key], undefined>
  // ) {
  //   this.reactiveForm.setControl(name, group);
  // }

  onSubmit() {
    const event = this._event();
    if (event === undefined) {
      return;
    }

    if (this.dateForm.valid) {
      console.log('this.dateForm onSubmit:', this.dateForm);
      this.setEvent.emit({
        ...event,
        eventDate: this.dateForm.controls.eventDate.value,
      });
      this.setStepNumber.emit(4);
    }
  }
}
